/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

// Debug CONTS
var DEBUG = false;

// Lo muevo a google.js
// Para Google Analytics
//window.dataLayer = window.dataLayer || [];
//function gtag(){dataLayer.push(arguments);}

var lang = 'esp';

/*
// Ya no se va a emplear LiveChat.
// Configuración de LiveChat
if (window.location)
{
    var path = window.location.pathname;
    if (path.substr(0, 4) == '/en/')
        lang = 'en';
}
var LHCChatOptions = {};
LHCChatOptions.opt = {widget_height: 340, widget_width: 300, popup_height: 520, popup_width: 500};
LHCChatOptions.attr = [];
LHCChatOptions.attr.push({'name': 'web-referrer', 'value': null, 'type': 'hidden', 'size': 0});
LHCChatOptions.callback = {
online_chat_started_cb : function(inst) {
    gtag('event', 'chat', {
        'event_category': 'contacto',
        'event_label': 'online',
        'value': 1
    });
},
offline_request_cb:function(inst) {
    gtag('event', 'chat', {
        'event_category': 'contacto',
        'event_label': 'online',
        'value': 0
    });
}};
*/

function goToEkomi()
{
    window.open('https://www.ekomi.es/testimonios-monemaes.html');
    return false;
}

function getURLParameter(sParam)
{
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split('&');
    for(var i=0;i<sURLVariables.length;i++)
    {
        var sParameterName = sURLVariables[i].split('=');
        if(sParameterName[0] == sParam)
        {
            return sParameterName[1];
        }
    }
}

var referrerData = '';

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {

        // All pages
        'common': {
            init: function () {

                // Ponemos valor al input hidden del referal y al chat
                $.get('/getReferalJS.php?urlRef=' + encodeURIComponent(document.referrer)+'&idref='+getURLParameter('idref'), function(referrerResponse) {

                    referrerData = JSON.parse(referrerResponse);

                    
		    /*
                    // Carga de LiveChat
		    if(lang == 'esp')
		    {
                    	var po = document.createElement('script');
                    	po.type = 'text/javascript';
                    	po.async = true;
                    	var location = (document.location) ? encodeURIComponent(window.location.href.substring(window.location.protocol.length)) : '';
                    	po.src = '//livehelper.a.voip-sys.com/index.php/' + lang + '/chat/getstatus/(click)/internal/(position)/bottom_right/(ma)/br/(check_operator_messages)/true/(top)/350/(units)/pixels/(leaveamessage)/true?&r=' + referrerData.Name + '&l=' + location;
                    	var s = document.getElementsByTagName('script')[0];
                    	s.parentNode.insertBefore(po, s);
		    }
		    */

		    // El número está quitado de la página en inglés "Pie de página (EN)"

                    // Número de teléfono en pie de página (página de Wordpress)
                    $('#monema-phone-number').attr("href", "tel:"+referrerData.WebPhoneNumber);
                    $('#monema-phone-number').text(referrerData.WebPhoneNumber.substring(0,3)+' '+referrerData.WebPhoneNumber.substring(3,6)+' '+referrerData.WebPhoneNumber.substring(6,9));

                    // Teléfono en artículos (Adrián)
                    $('.tel-monema a').attr("href", "tel:"+referrerData.WebPhoneNumber);
                    $('.tel-monema a').text(referrerData.WebPhoneNumber.substring(0,3)+' '+referrerData.WebPhoneNumber.substring(3,6)+' '+referrerData.WebPhoneNumber.substring(6,9));


		    if(lang == 'esp')
		    {
                    	// Número de teléfono en "utils" de templates/header.php (el que aparece en la parte izquierda del buscador)
	                $('#monema-phone-number-utils').attr("href", "tel:"+referrerData.WebPhoneNumber);
        	        $('#monema-phone-number-utils').text(referrerData.WebPhoneNumber.substring(0,3)+' '+referrerData.WebPhoneNumber.substring(3,6)+' '+referrerData.WebPhoneNumber.substring(6,9));

                    	// Número de teléfono en la barra superior para la versión móvil (metido en el templates/header.php)
                    	$('#monema-phone-number-topbar').attr("href", "tel:"+referrerData.WebPhoneNumber);
                    	$('#monema-phone-number-topbar').text(referrerData.WebPhoneNumber.substring(0,3)+' '+referrerData.WebPhoneNumber.substring(3,6)+' '+referrerData.WebPhoneNumber.substring(6,9));
		    }
		    else
		    {
                    	// Número de teléfono en "utils" de templates/header.php (el que aparece en la parte izquierda del buscador)
	                $('#monema-phone-number-utils').parent().remove();

                    	// Número de teléfono en la barra superior para la versión móvil (metido en el templates/header.php)
                    	$('#monema-phone-number-topbar').parent().remove();
                    }

                    $('#contactformreferal').val(referrerData.Name);

                    // Muevo esto a un script aparte para tener defer
                    // Google Analytics. El js lo he añadido en el monema-sage/templates/head.php
                    //gtag('js', new Date());
                    //gtag('config', 'UA-18473361-1', {
                    //    campaign: {
                    //        source: referrerData.Name,
                    //        medium: referrerData.Name,
                    //        name: referrerData.Name
                    //    }
                    //});

                    // Click2Call 
                    $('#click2calllink').attr("href", referrerData.c2c_link);
                    // I-antonio 16/05/2023 - quitamos evento de google analytics hasta ver si hace falta
                    //$('#click2calllink').click(function() {
                    //    gtag('event', 'click2call', {
                    //        'event_category': 'contacto',
                    //        'event_label': 'llamar',
                    //        'value': 1
                    //    });
                    //});
                    // F-antonio 16/05/2023 - quitamos evento de google analytics hasta ver si hace falta
                });
                                
                // Modales de precios
                if (window.location.href.indexOf("precios") > -1 || window.location.href.indexOf("prices") > -1) {
                    var iframeDefer = document.getElementsByTagName('iframe');
                    for (var i = 0; i < iframeDefer.length; i++) {
                        if (iframeDefer[i].getAttribute('data-src')) {
                            iframeDefer[i].setAttribute('src', iframeDefer[i].getAttribute('data-src'));
                        }
                    }
                }

                // JavaScript to be fired on all pages
                if (DEBUG)
                {
                    console.log('Adding click to Users');
                }
                $('.mn-login').click(function () {
                    if (DEBUG)
                    {
                        console.log('Show modalLogin');
                    }
                    $('#modalLogin').modal('show');
                    $('#loginForm')
                            .ajaxForm({
                                url: 'https://prov.a.voip-sys.com/login/login.php',
                                type: 'POST',
                                dataType: 'json',
                                beforeSubmit: function (arr, $form, options) {
                                    if (DEBUG)
                                    {
                                        console.log("LoginForm -> beforeSubmit: ");
                                        console.log(arr);
                                    }
                                    $('#loginError').hide();
                                    $('#loginButton').button('loading');
                                    return true;
                                },
                                success: function (response) {
                                    if (DEBUG)
                                    {
                                        console.log("LoginForm -> The server says: ");
                                        console.log(response);
                                    }
                                    window.location.replace(response.url);
                                },
                                error: function (xhr, textStatus, errorThrown) {
                                    if (DEBUG)
                                    {
                                        console.log("LoginForm -> in ajaxForm error: ");
                                        console.log(textStatus);
                                        console.log(errorThrown);
                                    }
                                    if (errorThrown == 'Unauthorized')
                                    {
                                        $('#loginError').show();
                                    }
                                    else if(errorThrown == 'Number used in several extensions.')
                                    {
                                        $('#loginNumberNotAllowed').show();
                                    }
                                    else
                                    {
                                        $('#errorNotCaptured').text(errorThrown);
                                        $('#anotherError').show();
                                    }
                                    $('#loginButton').button('reset');
                                }
                            })
                            ;

                    return false;
                });
                $('#view-rates-link').click(function () {
                    $('#modalCallRates').modal('show');

                });
                $('#view-didprices-link').click(function () {
                    $('#modalDIDPrices').modal('show');
                });

                // Modal para cita demo.
                $('#pedir-cita').click(function(){
                    //console.log("Abriendo modal de citas."); // BORRAR
                    // Obtener los valores de los campos del formulario.
                    var nombre = encodeURIComponent($('input[name="nombre"]').val());
                    var apellidos = encodeURIComponent($('input[name="apellidos"]').val());
                    var email = encodeURIComponent($('input[name="emailaddress"]').val());
                    var empresa = encodeURIComponent($('input[name="nombreEmpresa"]').val());
                    var numeroTelefono = encodeURIComponent($('input[name="telefono"]').val());
                    var puestos = encodeURIComponent($('select[name="puestos"]').val());

                    if(nombre && apellidos && email && empresa && puestos && numeroTelefono){
                        // Obtener la URL con el puerto correspondiente, si no hay puerto no se pone.
                        var url = "https://www.monema.es%PUERTO%/app/themes/monema-sage/sistema_citas_demo/index.php?";
                        var port = location.host.split(":")[1];
                        if(port == undefined){ url = url.replace("%PUERTO%", ""); }
                        else { url = url.replace("%PUERTO%", ":" + port); }
                        url += 'name=' + nombre;
                        url += '&surname=' + apellidos;
                        url += '&email=' + email;
                        url += '&company=' + empresa;
                        url += '&employees=' + puestos;
                        url += '&phoneNumber=' + numeroTelefono;
                        url += '&lang=' + $('#iframeCitas').attr('data-value');

                        // Cargar el src del iframe y mostrar el modal.
                        $('#iframeCitas').attr("src", url);
                        $('#modalCitaDemo').modal('show');
                    }else{
                        console.warn("NOTA: No se ha abierto el modal por falta de parametros.");
                    }
                });

                // Añadimos a la imagen con el listado de integraciones el mapa de clicks
                $('.integrations-image img').after('<map name="integrations-map"><area target="" alt="Zoho CRM" title="Zoho CRM" href="/integracion-zoho-crm/" coords="3,6,299,75" shape="rect"><area target="" alt="Zendesk" title="Zendesk" href="/integracion-zendesk/" coords="7,83,295,149" shape="rect"><area target="" alt="Freshdesk" title="Freshdesk" href="/integracion-freshdesk/" coords="6,157,296,225" shape="rect"><area target="" alt="vTiger" title="vTiger" href="/integracion-vtiger/" coords="6,233,297,300" shape="rect"><area target="" alt="Chrome Extension" title="Chrome Extension" href="/knowledge-base/como-configurar-extension-para-google-chrome/" coords="7,308,294,372" shape="rect"><area target="" alt="Pipedrive" title="Pipedrive" href="/integracion-pipedrive/" coords="8,382,296,447" shape="rect"><area target="" alt="SugarCRM" title="SugarCRM" href="/integracion-sugarcrm/" coords="8,455,299,526" shape="rect"><area target="" alt="Google Contacts" title="Google Contacts" href="/integracion-google-contacts/" coords="7,534,296,601" shape="rect"><area target="" alt="API" title="API" href="/fonema-api/" coords="8,609,296,672" shape="rect"></map>');
                $('.integrations-image img').attr('usemap','#integrations-map');                
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired

            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page

                // Precargo las imágenes que usa el carrusel para que tarde menos
                images = [];
		// No precargo imágenes para que tarde menos en cargar la página porque ya no están en el carrusel
                //preload(
                //        "/app/themes/monema-sage/dist/svgs/icon-list-home-1.svg",
                //        "/app/themes/monema-sage/dist/svgs/icon-list-home-2.svg",
                //        "/app/themes/monema-sage/dist/svgs/icon-list-home-3.svg"
                //        );

                home_carousel();
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    // --------------------------
    $(document).ready(UTIL.loadEvents);
    // --------------------------
    $(window).on("load resize scroll", function () {
        set_scroll();
    });
    //--------------------------------

    function set_scroll() {

        var scroll = $(window).scrollTop();
        var width_w = $(window).width();

        if (scroll > 40) {
            if (!$('header.navbar').hasClass('scroll')) {
                $('header.navbar').addClass('scroll');
                $('.main').addClass('scroll');
            }
        } else {
            $('header.navbar').removeClass('scroll');
            $('.main').removeClass('scroll');
        }
    }
    // --------------------------
    function home_carousel() {

        if (getWidthViewport() < 768) {
            $('#home-carousel').carousel('pause');
        } else {
            $('#home-carousel').on('slide.bs.carousel', function (e) {
                var index = $(e.relatedTarget).index();
                if (DEBUG)
                    console.log(e);
                switch (index) {
                    case 0:
                        $('#home-carousel-slider, #home-carousel-images').addClass('step0');
                        $('#home-carousel-slider, #home-carousel-images').removeClass('step1');
                        $('#home-carousel-slider, #home-carousel-images').removeClass('step2');
                        break;
                    case 1:
                        $('#home-carousel-slider, #home-carousel-images').addClass('step1');
                        $('#home-carousel-slider, #home-carousel-images').removeClass('step0');
                        $('#home-carousel-slider, #home-carousel-images').removeClass('step2');
                        break;
                    case 2:
                        $('#home-carousel-slider, #home-carousel-images').addClass('step2');
                        $('#home-carousel-slider, #home-carousel-images').removeClass('step0');
                        $('#home-carousel-slider, #home-carousel-images').removeClass('step1');
                        break;
                }
            });

            $('#home-carousel .carousel-indicators li').on('mouseup', function (e) {
                $('#home-carousel').carousel({pause: true, interval: false});
                $('#home-carousel').carousel('pause');
            });
        }
    }
    
    function preload() {
        for (i = 0; i < preload.arguments.length; i++) {
            images[i] = new Image();
            images[i].src = preload.arguments[i];
        }
    }

    // --------------------------
    function getWidthViewport() {
        return $(window).width();
    }

})(jQuery); // Fully reference jQuery after this point.
